<template>
  <v-dialog max-width="650" :value="value" @input="$emit('input', $event)">
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar dark :class="[error ? 'error' : 'primary']">
          {{ error ? "Invalid" : title }}</v-toolbar
        >
        <v-row
          :style="error ? '' : 'min-height: 150px'"
          class="ma-0 text-center class cropper-parent"
        >
          <v-card-text v-if="src === ''" class="pt-6 text-center">
            Image src not provided
          </v-card-text>
          <cropper
            v-else-if="!error"
            :src="src"
            @change="onCrop"
            @error="onError"
            :stencil-props="{
              aspectRatio: aspectRatio,
            }"
          />
          <v-card-text v-else class="pt-6 text-center">
            Image selected is not valid.
          </v-card-text>
        </v-row>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false" tile small>{{
            cancelText
          }}</v-btn>
          <v-btn
            v-if="!error"
            text
            @click="submit"
            class="primary ml-2"
            tile
            small
            >{{ submitText }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { reactive, toRefs, watch } from "@vue/composition-api";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

const state = reactive({
  error: false,
  imageData: "",
});

export default {
  props: {
    value: {
      type: Boolean,
    },
    aspectRatio: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: "Resize Image",
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    src: {
      type: String,
      default: "",
    },
    change: {
      type: Function,
      default: () => {},
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  components: { Cropper },
  setup(props) {
    watch(
      () => props.src,
      (value) => {
        if (value) {
          state.error = false;
        }
      }
    );
    return {
      ...toRefs(state),
      onError() {
        state.error = true;
      },
      onCrop(e) {
        props.change && props.change(e);
        state.imageData = e.canvas.toDataURL("image/jpeg");
      },
      submit() {
        props.onSubmit && props.onSubmit(state.imageData);
      },
    };
  },
};
</script>
